<script setup lang="ts">
import GroupItem from "~/components/group_item.vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";

definePageMeta({
  auth: false,
});

const GET_GROUPS = gql`
  query {
    groups {
      id
      name
      system {
        name
      }
      location {
        name
      }
      players {
        email
      }
      gamemaster {
        email
      }
    }
  }
`;

const { result, loading, error } = useQuery(GET_GROUPS);

const groups = computed(() => {
  return result.value?.groups || [];
});
</script>

<template>
  <div class="px-6 py-10">
    <h2 class="mb-8 text-center text-2xl">New Groups</h2>
    <div v-if="loading" class="text-blue-500">Loading groups...</div>
    <div v-else-if="error" class="text-red-500">Error loading groups: {{ error.message }}</div>
    <div
      v-else-if="groups && groups.length"
      class="grid grid-cols-1 gap-12 px-6 py-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
    >
      <GroupItem v-for="group in groups" :group="group" :key="group.id" />
    </div>
    <div v-else class="text-gray-500">No groups available.</div>
  </div>
</template>
